/* Баннер рандомного фильма */
.banner-container {
    position: relative;
    height: 810px;
    width: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.banner-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(47, 53, 56, 0) 0%, #2f3538 97.01%);
    transform: rotate(180deg);
    z-index: 0;
}

.banner-image {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: brightness(40%);
}

.movie-info {
    position: absolute;
    color: white;
    left: 50px;
    z-index: 1;
    text-align: left;
}

.movie-info__rating {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 95px;
    height: 39px;
    font-family: var(--font-family);
    font-weight: 900;
    font-size: 32px;
    text-align: center;
    color: var(--dop-1);
    background-color: var(--green-1);
    padding: 10px;
    border-radius: 30px;
    z-index: 2;
}

.movie-info__wrap {
    display: flex;
    align-items: center;
}

.movie-info__poster {
    width: 223px;
    height: auto;
    margin-right: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.movie-info__box {
    text-align: left;
    max-width: 1198px;
}

.movie-info__title {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 70px;
    color: var(--green-5);
}

.movie-info__desc {
    display: flex;
    gap: 15px;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 14px;
    color: var(--green-5);
}

.movie-info__year,
.movie-info__genre {
    position: relative;
}

.movie-info__year span::after,
.movie-info__genre span::after {
    content: '';
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: var(--dop-3);
    margin-left: 15px;
    vertical-align: middle;
}

.movie-info__about {
    margin-top: 20px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    color: var(--green-5);
}

.movie-info__btn {
    display: inline-block;
    margin-top: 20px;
    padding: 20px 50px;
    background-color: var(--dop-1);
    text-decoration: none;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: var(--green-1);
    border-radius: 50px;
}