/* Шапка главной страницы */

.header {
    display: flex;
    align-items: center;
    background-color: var(--main-bg-color);
    opacity: 0.95;
    color: var(--main-color);
    position: relative;
    width: 100%;
    z-index: 5;
}

.header__logo {
    width: 157px;
    height: 70px;
    padding-left: 15px;
}

.header__nav {
    width: 100%;
    padding-left: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__nav_item {
    list-style: none;
    display: flex;
    gap: 50px;
}

.header__nav_items {
    position: relative;
}

.header__nav_items a {
    color: var(--main-color);
    font-weight: 500;
    font-size: 14px;
}

.header__nav_items a:hover {
    color: var(--dop-2);
}

.header__search_box {
    background: var(--green-1);
    border-radius: 25px;
    margin-right: 20px;
    display: flex;
}

.header__search_box.expanded .header__search_input {
    width: 30vw;
    padding: 0 6px;
}

.header__search_button {
    border: none;
    color: var(--main-color);
    border-radius: 50%;
    background: var(--green-1);
    height: 40px;
    width: 40px;
    transition: 0.4s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header__search_input {
    border: none;
    background: none;
    outline: none;
    float: right;
    padding: 0;
    color: var(--main-color);
    font-size: 14px;
    font-weight: 300;
    transition: 0.4s;
    line-height: 40px;
    width: 0px;
    order: -1;
}

.header__search_img {
    width: 24px; 
    height: 24px;
}

/* Выпадающее подменю шапки */

.submenu {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgb(47, 53, 56);
    opacity: 0.95;
    z-index: 4;
    padding: 10px 0;
    display: none;
}

.submenu__dropdown-content {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    gap: 20px;
    max-width: 1196px;
    margin: 0 auto;
    border-top: #1AECDF solid 1px;
}

.submenu__dropdown-column {
    padding: 0 20px;
}

.submenu__dropdown-column a {
    display: block;
    color: white;
    padding: 10px 0px;
}

.submenu__dropdown_link-bold {
    font-weight: 500;
    font-size: 14px;
}

.submenu__dropdown-submenu a:hover {
    color: #1AECDF;
}

.submenu__dropdown-submenu {
    margin-top: 8px;
}

.submenu__dropdown_link {
    font-weight: 300;
    font-size: 14px;
}

.submenu__dropdown_link:hover {
    border-bottom: #1AECDF solid 1px;
}
