/* Боковая панель навигации */
.aside-navbar {
    position: fixed;
    z-index: 2;
    width: 80px;
    transition: width 0.5s ease, transform 0.5s ease;
}

.aside-navbar:hover {
    width: 243px;
}

.aside-navbar__wrapper {
    height: 770px;
    padding: 20px 0 20px 0px;
    backdrop-filter: blur(15px);
    background: rgba(29, 29, 29, 0.85);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    transition: padding-left 0.5s ease;
}

.aside-navbar:hover .aside-navbar__wrapper {
    align-items: flex-start;
    padding-left: 20px;
}

.aside-navbar__links,
.aside-navbar__setting,
.aside-navbar__avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.aside-navbar:hover .aside-navbar__links,
.aside-navbar:hover .aside-navbar__setting,
.aside-navbar:hover .aside-navbar__avatar {
    align-items: flex-start;
}

.aside-navbar__links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 30px;
}

.aside-navbar__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: transform 0.5s ease;
    white-space: nowrap;
}

.aside-navbar:hover .aside-navbar__link {
    justify-content: flex-start;
}

.aside-navbar__txt {
    display: none;
    padding-left: 40px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    color: var(--green-5);
    transition: opacity 0.5s ease;
    white-space: nowrap;
}

.aside-navbar__avatar .aside-navbar__txt {
    white-space: normal;
}

.aside-navbar:hover .aside-navbar__txt {
    display: block;
    opacity: 1;
}

.aside-navbar__setting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    border-top: 1px solid var(--green-2);
}

.aside-navbar__icon {
    max-height: 24px;
    max-width: 24px;
    transition: transform 0.5s ease;
}

.aside-navbar__avatar-img {
    height: 58px;
    max-width: 58px;
    transition: transform 0.5s ease;
}

.aside-navbar:hover .aside-navbar__icon,
.aside-navbar:hover .aside-navbar__avatar-img {
    transform: translateX(20px);
}
