@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');

:root {
	--green-0: #0d0d0d;
	--green-1: #1d1d1d;
	--green-2: #2f3538;
	--green-3: #c5c5c5;
	--green-4: #eee;
	--green-5: #fff;
	--dop-1: #e2f026;
	--dop-2: #1aecdf;
	--dop-3: #1dc4ee;
	--dop-4: #1761da;
	--dop-5: #6906dd;
	--dop-6: #a203b0;
	--dop-7: #ec33cc;
    --font-family: "Montserrat", sans-serif;
    --second-family: "Gotham Pro", sans-serif;
    --third-family: "PT Sans", sans-serif;
    --font3: "Plus Jakarta Display", sans-serif;
    --main-bg-color: rgb(47, 53, 56);
    --main-color: white;
    --icon-color: #e2f026, #1aecdf, #1dc4ee, #1761da, #6906dd, #a203b0, #ec33cc;
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Montserrat", sans-serif;
    background-color: rgb(47, 53, 56);
    opacity: 0.95;
}

.container {
    max-width: 1440px;
    margin: 0 auto;
}

.center {
    padding-left: calc(50% - 720px);
    padding-right: calc(50% - 720px);
}

.container_midle {
    max-width: 1196px;
    margin: 0 auto;
    position: relative;
}

.center_midle {
    padding-left: calc(50% - 598px);
    padding-right: calc(50% - 598px);
}

a {
    text-decoration: none;
}
